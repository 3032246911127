<template>
  <sm-long-process
    :isLoadingPage="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :controllerName="controllerName"
    :updateFields="updateFields"
    :form="form"
    :requiredFields="requiredFields"
    :disabledButton="isDisabledStartButton"
    @clearForm="handleClearForm"
  >
    <template #csvFileGuid>
      <sm-fileinput
        :multiple="false"
        accept=".csv"
        @changeFile="(file) => handleChangeFile(file)"
        @removeFile="handleRemoveFile"
      />
    </template>
  </sm-long-process>
</template>

<script>
// vuex
import { mapActions } from 'vuex';
// components
import SmLongProcess from '@/components/views/SmLongProcess.vue';
import SmFileinput from '@/components/common/forms/SmFileinput.vue';

export default {
  name: 'CheckPaymentsRegistryProcessor',

  components: {
    SmLongProcess,
    SmFileinput,
  },

  data() {
    return {
      isLoadingPage: false,
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Сверка реестров оплат',
        },
      ],
      pageHeader: 'Сверка реестров оплат',
      controllerName: 'CheckPaymentsRegistryProcessor',
      form: {
        csvFileGuid: null,
        dateFrom: null,
        dateTo: null,
        paymentSystem: 'Tinkoff',
      },
      initialForm: {
        csvFileGuid: null,
        dateFrom: null,
        dateTo: null,
        paymentSystem: 'Tinkoff',
      },
      requiredFields: {
        fields: [
          { key: 'csvFileGuid', label: 'Файл для сверки' },
        ],
        requiredAll: true,
      },
      isUploadFileProcess: false,
      fileInfo: null,
    };
  },

  computed: {
    updateFields() {
      return [
        {
          type: 'file',
          key: 'csvFileGuid',
          label: 'Файл для сверки',
        },
        {
          type: 'date',
          key: 'dateFrom',
          label: 'Дата начала периода',
          attributes: {
            disabledDate: this.startPeriodDateDisabled,
          },
        },
        {
          type: 'date',
          key: 'dateTo',
          label: 'Дата окончания периода',
          attributes: {
            disabledDate: this.endPeriodDateDisabled,
          },
        },
        {
          type: 'select',
          key: 'paymentSystem',
          label: 'Платёжная система',
          list: [
            {
              name: 'Tinkoff',
              value: 'Tinkoff',
            },
            {
              name: 'A3',
              value: 'A3',
            },
          ],
        },
      ];
    },

    isDisabledStartButton() {
      return !this.form.csvFileGuid || this.isUploadFileProcess;
    }
  },

  methods: {
    ...mapActions({
      addFile: 'pendingFiles/addFile',
      removeFiles: 'pendingFiles/removeFiles',
    }),

    handleClearForm() {
      this.form = this.lodash.cloneDeep(this.initialForm);
    },

    handleChangeFile(file) {
      this.isUploadFileProcess = true;

      let fd = new FormData();
      fd.append('file', file[0], file[0].name);

      this.addFile(fd).then((newFileId) => {
        this.form.csvFileGuid = newFileId;
        this.fileInfo = {
          fileName: file[0].name,
          newFileId,
          updateAction: 'new',
        };

        this.$notify({
          header: 'Файл успешно загружен',
          type: 'success',
          timer: 5000,
        });

        this.isUploadFileProcess = false;
      });
    },

    handleRemoveFile() {
      this.removeFiles({
        filesToRemove: [this.form.csvFileGuid],
      }).then(() => {
        this.form.csvFileGuid = null;
        this.fileInfo = null;
      });
    },

    startPeriodDateDisabled(date) {
      if (!this.form.dateTo) return false;

      const formattedEndPeriodDate = this.$moment(this.form.dateTo, 'DD.MM.YYYY');

      return this.$moment(date).isAfter(formattedEndPeriodDate);
    },

    endPeriodDateDisabled(date) {
      if (!this.form.dateFrom) return false;

      const formattedStartPeriodDate = this.$moment(this.form.dateFrom, 'DD.MM.YYYY');

      return this.$moment(date).isBefore(formattedStartPeriodDate);
    },
  },
};
</script>