<template>
  <sm-page-loader v-if="isLoadingPage" />

  <div v-else class="long-process">
    <!-- NAV -->
    <sm-breadcrumbs
      v-if="breadcrumbs"
      class="long-process__breadcrumbs"
      :items="breadcrumbs"
    />
    <!-- / NAV -->

    <!-- PAGE HEADER -->
    <h2 class="long-process__header">{{ pageHeader }}</h2>
    <!-- / PAGE HEADER -->

    <!--  -->
    <slot name="top-panel"></slot>
    <!-- /  -->

    <sm-button
      class="long-process__button long-process__button--start"
      :disabled="isDisabledStartButton"
      @click="onStartProcess"
      >Запустить обработку</sm-button
    >

    <sm-modal
      v-if="updateFields"
      :key="'modal_' + modalRenderKey"
      :show="showModal"
      :modalTitle="modalTitle"
      class="form-modal"
      @close="onCloseModal"
    >
      <template #body>
        <sm-editable-form 
          v-model="modalForm" 
          :fields="updateFields"          
          @changeFile="handleChangeFieldFile"
          @downloadFile="handleDownloadFieldFile"
          @removeFile="handleRemoveFieldFile"
        >
          <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="field">
            <slot :name="slot" v-bind="field"></slot>
          </template>
        </sm-editable-form>
        <div class="form-modal__buttons">
          <slot name="footer">
            <sm-button
              class="form-modal__button"
              :isLoading="isLoadingProcessButton"
              :disabled="disabledButton"
              @click="onConfirmProcess"
              >Запустить</sm-button
            >
            <sm-button
              neutrall
              outline
              class="form-modal__button"
              @click="onCloseModal"
              >Отменить</sm-button
            >
          </slot>
        </div>
      </template>
    </sm-modal>

    <!-- TABS NAV -->
    <sm-tabs
      v-if="tabs.length > 1"
      class="long-process-tabs"
      :tabs="tabs"
      :activeTab="activeTab"
      @activeTab="onSelectTab"
    />
    <!-- / TABS NAV -->

    <!-- TAB CONTENT -->
    <template v-for="(item, tabIndex) in allFields">
      <div
        v-if="activeTab === tabIndex"
        :key="tabIndex"
        class="long-process-content"
      >
        <div v-if="item.info && showInfo" class="long-process-content__info">
          <div
            v-for="(row, index) in item.info"
            :key="index"
            class="long-process-content__row"
          >
            <p class="long-process-content__row-label">{{ row.label }}</p>
            <div class="long-process-content__row-key">{{ row.key }}</div>
          </div>
          <div
            v-if="info.data.hasFiles && info.data.files.length"
            class="long-process-content__row"
          >
            <p class="long-process-content__row-label">Файлы:</p>
            <div class="long-process-content__row-key">
              <div
                v-for="file in info.data.files"
                :key="file.id"
                class="long-process-content__files"
                @click="onDownloadFile(file.id)"
              >
                <a>{{ file.name }}</a>
              </div>
            </div>
          </div>
          <sm-button
            v-if="showCancelProcessButton"
            class="long-process__button long-process__button--cancel"
            :isLoading="isStopProcessLoading"
            @click="onStopProcess"
            >Отменить обработку</sm-button
          >
        </div>
        <div v-if="item.text" class="long-process-content__info-text">{{ item.text }}</div>
        <sm-datatable
          v-else-if="item.table"
          class="long-process__logs-table"
          :caption="item.table.caption"
          :headers="item.table.headers"
          :items="item.table.items"
        >
          <template #actions="{ row }">
            <sm-tooltip
              v-if="item.table.showInfoButton"
              position="bottom"
              text="Подробнее"
              class="editable-list__datatable-tooltip"
            >
              <sm-button
                colorType="success"
                class="editable-list__datatable-button"
                @click="onShowLog(item.table.key, row.id)"
              >
                <sm-icon
                  name="information-circle"
                  class="editable-list__datatable-button-icon"
                />
              </sm-button>
            </sm-tooltip>
          </template>
        </sm-datatable>

        <sm-modal
          class="long-process-completed"
          :show="showModalLog"
          :fields="modalLogFields"
          modalTitle="Информация о завершенной обработке"
          @close="onCloseLogModal"
        >
          <template #body>
            <sm-page-loader
              v-if="isLoadingModalLog"
              class="long-process-completed__loader"
            />
            <template v-else>
              <div
                v-for="field in modalLogFields"
                :key="field.key"
                class="long-process-completed__row"
              >
                <div class="long-process-completed__label">
                  {{ field.label }}:
                </div>
                <div class="long-process-completed__text">
                  {{ field.value }}
                </div>
              </div>
            </template>
          </template>
        </sm-modal>
      </div>
    </template>
    <!-- / TAB CONTENT -->
  </div>
</template>

<script>
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';
import SmTabs from '@/components/common/SmTabs.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmTooltip from '@/components/common/SmTooltip.vue';
import SmModal from '@/components/common/modals/SmModal.vue';
import SmEditableForm from '@/components/common/forms/SmEditableForm.vue';

export default {
  name: 'smLongProcess',

  components: {
    SmPageLoader,
    SmBreadcrumbs,
    SmDatatable,
    SmTabs,
    SmButton,
    SmIcon,
    SmTooltip,
    SmModal,
    SmEditableForm,
  },

  provide() {
    return {
      previosPageParams: this.previosPageParams,
    };
  },

  props: {
    isLoadingPage: {
      type: Boolean,
      default: false,
    },

    breadcrumbs: {
      type: Array,
    },

    pageHeader: {
      type: String,
    },

    controllerName: {
      type: String,
      require: true,
    },

    updateFields: {
      type: Array,
    },

    form: {
      type: Object,
    },

    requiredFields: {
      type: Object,
      default: () => {},
    },

    hiddenParams: {
      type: Object,
      default: () => {},
    },

    disabledButton: {
      type: Boolean,
      default: false,
    },

    isCustomDisabledStartButton: {
      type: Boolean,
      default: false,
    },

    isCustomForm: {
      type: Boolean,
      default: false,
    },

    additionalFields: {
      type: Object,
    },

    isCompleteProcessesTab: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      previosPageParams: {
        value: null,
      },
      currentProcessLogText: '',
      modalForm: {},
      showModal: false,
      modalTitle: 'Запуск обработки',
      isLoadingProcessButton: false,
      isStopProcessLoading: false,
      updateInterval: null,
      showModalLog: false,
      modalLogFields: [],
      isLoadingModalLog: false,
      isStartingProcess: false,
      modalRenderKey: 0,
      isStartProcessButtonDisabled: false,
      showInfo: false,
    };
  },

  computed: {
    ...mapState({
      info: (state) => state.longProcessService.info,
      list: (state) => state.longProcessService.list,
    }),

    isDisabledStartButton() {
      const disabledCondition = this.isStartProcessButtonDisabled
        || this.isStopProcess
        || this.isStartingProcess
        || this.isCustomDisabledStartButton;
        
      return disabledCondition;
    },

    isStopProcess() {
      return this.info.data?.isCancelledByUser && !this.completeDate;
    },

    isCompleted() {
      return this.info.data?.isCompleted;
    },

    startDate() {
      return this.info.data?.started || '';
    },

    completeDate() {
      return this.info.data?.completed;
    },

    statusText() {
      return this.info.data?.status || '';
    },

    processStatus() {
      if (this.isStopProcess) {
        return 'Остановка обработки в процессе';
      }

      if (this.isStartingProcess) return '';

      return this.completeDate
        ? this.statusText
        : `${this.statusText} Обработка запущена ${this.startDate}`;
    },

    showCancelProcessButton() {
      return !this.isStopProcessLoading
        ? !this.info.data.isCancelledByUser && !this.isCompleted
        : true;
    },

    currentLogText() {
      if (!this.showInfo) return '';

      if (this.isStopProcess) {
        return 'Остановка обработки в процессе. Лог появится после завершения остановки';
      }

      const isProcessInProgress = this.startDate
        && !this.completeDate
        !this.info.data.isCancelledByUser &&
        !this.info.data.isInEmergency;

      if ( isProcessInProgress ) {
        return 'Обработка еще не завершена. Лог появится после завершения обработки';
      }

      return this.currentProcessLogText;
    },

    tableList() {
      if (!this.list.data) return [];

      const completedList = this.list.data.filter((item) => item.isCompleted);

      return completedList.map((item) => ({
        ...item,
        hasErrors: item.hasErrors ? 'Да' : 'Нет',
        isCancelledByUser: item.isCancelledByUser ? 'Да' : 'Нет',
        isInEmergency: item.isInEmergency ? 'Да' : 'Нет',
      }));
    },

    modalLogTable() {
      return {
        caption: 'Завершенные обработки',
        headers: [
          {
            text: 'Дата запуска',
            alias: 'started',
          },
          {
            text: 'Дата завершения',
            alias: 'completed',
          },
          {
            text: 'Завершилась с ошибками',
            alias: 'hasErrors',
          },
          {
            text: 'Отменена пользователем',
            alias: 'isCancelledByUser',
          },
          {
            text: 'Завершена аварийно',
            alias: 'isInEmergency',
          },
          {
            alias: 'actions',
          },
        ],
        items: this.tableList,
        key: 'completedProcesses',
        modalField: [
          {
            key: 'started',
            label: 'Дата запуска',
          },
          {
            key: 'completed',
            label: 'Дата завершения',
          },
          {
            key: 'status',
            label: 'Статус',
          },
          {
            key: 'log',
            label: 'Текст лога',
          },
        ],
        showInfoButton: true,
      };
    },

    fields() {
      const fieldList = [
        {
          tab: 'Общая информация',
          info: [
            {
              label: 'Статус: ',
              key: this.processStatus,
            },
            {
              label: 'Дата запуска: ',
              key: this.startDate,
            },
            {
              label: 'Дата завершения: ',
              key: this.completeDate,
            },
          ],
        },
        {
          tab: 'Лог обработки',
          text: this.currentLogText,
        },
      ];

      if (this.isCompleteProcessesTab) {
        fieldList.push(
          {
            tab: 'Завершенные обработки',
            table: this.modalLogTable,
          }
        )
      }

      return fieldList;
    },

    allFields() {
      const additionalFields = this.additionalFields;
      const fields = this.fields;

      if (!additionalFields) return fields;

      const cloneFields = this.lodash.cloneDeep(fields);

      for (let tabName in additionalFields) {
        const currentAdditionalField = additionalFields[tabName];
        const currentField = cloneFields.find((field) => field.tab === tabName);

        if (!currentField) continue;

        if ('info' in currentField && 'info' in currentAdditionalField) {
          currentField.info = [
            ...currentField.info,
            ...currentAdditionalField.info,
          ];
        }

        if ('table' in currentField && 'table' in currentAdditionalField) {
          currentField.table = {
            ...currentField.table,
            ...currentAdditionalField.table,
          };
        }

        if ('text' in currentField && 'text' in currentAdditionalField) {
          currentField.text += currentAdditionalField.text;
        }
      }

      return cloneFields;
    },

    tabs() {
      return this.fields.map((item) => ({
        text: item.tab,
      }));
    },

    activeTab() {
      if (this.$route?.query?.tab) {
        return +this.$route.query.tab;
      }
      return 0;
    },
  },

  watch: {
    isCompleted(newVal) {
      if (newVal && this.completeDate) {
        this.getLogInfo();
        this.getList({ controllerName: this.controllerName });
        if (this.updateInterval) clearInterval(this.updateInterval);
        if (this.info.data.isCancelledByUser) this.isStopProcessLoading = false;
        this.isStartProcessButtonDisabled = false;
      } else if (
        !newVal &&
        !this.completeDate &&
        !this.info.data.isCancelledByUser
      ) {
        this.showInfo = true;
        this.isStartProcessButtonDisabled = true;
      }
    },
  },

  created() {
    this.previosPageParams.value = this.$route.params;

    if (this.tabs.length > 1 && this.$route.query.tab === undefined) {
      this.$router.push({
        query: { tab: this.activeTab },
      });
    }

    this.getInfo({
      controllerName: this.controllerName,
    }).then((result) => {
      this.showInfo = true;
      if (result?.isCompleted) {
        this.getLogInfo();
      }
    });
    this.getList({ controllerName: this.controllerName });
  },

  methods: {
    ...mapActions({
      getInfo: 'longProcessService/getInfo',
      getLog: 'longProcessService/getLog',
      startProcess: 'longProcessService/startProcess',
      stopProcess: 'longProcessService/stopProcess',
      getList: 'longProcessService/getList',
      downloadFile: 'longProcessService/downloadFile',
    }),

    onSelectTab(index) {
      this.$router.push({
        query: { tab: index },
      });
    },

    handleChangeFieldFile(source) {
      this.$emit('changeFieldFile', source);
    },

    handleDownloadFieldFile(source) {  
      this.$emit('downloadFieldFile', source); 
    },

    handleRemoveFieldFile(source) {
      this.$emit('removeFieldFile', source);
    },

    onDownloadFile(id) {
      this.downloadFile({
        controllerName: this.controllerName,
        id: id,
      });
    },

    getLogInfo() {
      this.getLog({
        controllerName: this.controllerName,
        id: this.info.data.id,
      }).then((res) => {
        this.currentProcessLogText = res;
      }).catch(() => {
        this.currentProcessLogText = 'Ошибка запроса. Лог недоступен';
      });
    },

    onStartProcess() {
      if (this.updateFields && this.form) {
        this.showModal = true;
        this.modalForm = this.form;
      } else {
        this.isStartingProcess = true;
        this.showInfo = false;

        const params = this.isCustomForm ? this.form : this.modalForm;

        this.startProcess({
          controllerName: this.controllerName,
          params,
        })
          .then((result) => {
            if (result?.isSucceed) {
              this.$notify({
                header: 'Обработка запущена успешно',
                type: 'success',
                timer: 3000,
              });
              this.pollProcessCompletion();
            }
          })
          .finally(() => {
            this.isLoadingProcessButton = false;
            this.onCloseModal();
          });
      }
    },

    onCloseModal() {
      this.showModal = false;
      this.$emit('clearForm');
      this.modalRenderKey++;
    },

    pollProcessCompletion() {
      this.getInfo({ controllerName: this.controllerName })
        .then(({ isCompleted }) => {
          this.showInfo = true;

          if (isCompleted) {
            this.completeProcess();
            return;
          }
          this.retryPolling();
        })
        .finally(() => {
          this.isStartingProcess = false;
        });
    },

    completeProcess() {
      this.getLogInfo();
      this.getList({ controllerName: this.controllerName });
    },

    retryPolling() {
      setTimeout(() => {
        this.pollProcessCompletion();
      }, 2000);
    },

    // Проверяет, что все обязательные поля заполнены
    checkRequiredAll(form, fields) {
      const emptyFields = [];

      fields.forEach(({ parentKey, key, label }) => {
        if (parentKey) {
          // Проверяет поля внутри массивов, если есть parentKey
          form[parentKey]?.forEach((element) => {
            if (element[key] == null) {
              emptyFields.push(label);
            }
          });
        } else {
          // Проверяет поля вне массивов
          if (form[key] == null) {
            emptyFields.push(label);
          }
        }
      });

      return emptyFields;
    },

    // Проверяет, что хотя бы одно из обязательных полей заполнено
    checkAtLeastOneFieldFilled(form, fields) {
      // Функция для проверки, заполнено ли значение
      const isFilled = (value) => value || value === 0;

      // Проверка полей
      const checkField = (field) => {
        if (field.parentKey) {
          // Если есть parentKey, проверяем элементы в массиве
          return form[field.parentKey]?.some(element => isFilled(element[field.key]));
        } else {
          // Проверяем значение напрямую
          return isFilled(form[field.key]);
        }
      };

      // Возвращаем результат проверки на наличие хотя бы одного заполненного поля
      return fields.some(checkField);
    },

    // Формирует сообщение об ошибке
    constructErrorMessage(fields, messagePrefix) {
      return `${messagePrefix}: ${fields.join(', ')}`;
    },

    // Основная функция валидации формы
    validateForm(form, requiredFields) {
      let message = null;

      if (requiredFields.requiredAll) {
        // Проверяет, что все обязательные поля заполнены
        const emptyFields = this.checkRequiredAll(form, requiredFields.fields);

        if (emptyFields.length) {
          message = this.constructErrorMessage(emptyFields, 'Не заполнены обязательные поля');
        }
      } else {
        // Проверяет, что хотя бы одно из обязательных полей заполнено
        const fieldContains = this.checkAtLeastOneFieldFilled(form, requiredFields.fields);

        if (!fieldContains) {
          message = this.constructErrorMessage(requiredFields.fields, 'Должно быть заполнено одно из полей');
        }
      }

      return message;
    },

    onConfirmProcess() {
      if (this.requiredFields && this.requiredFields.fields.length) {
        const header = this.validateForm(this.form, this.requiredFields);

        if (header) {
          this.$notify({
            header,
            type: 'error',
            timer: 5000,
          });
          return;
        }
      }

      this.isLoadingProcessButton = true;
      this.isStartingProcess = true;
      this.showInfo = false;

      const params =
        this.hiddenParams && Object.keys(this.hiddenParams).length
          ? {
              ...this.modalForm,
              ...this.hiddenParams,
            }
          : this.modalForm;

      this.startProcess({
        controllerName: this.controllerName,
        params: params,
      })
        .then(({ isSucceed }) => {
          if (isSucceed) {
            this.$notify({
              header: 'Обработка запущена успешно',
              type: 'success',
              timer: 3000,
            });
            this.pollProcessCompletion()
          }
        })
        .finally(() => {
          this.isLoadingProcessButton = false;
          this.onCloseModal();
        });
    },

    onStopProcess() {
      this.isStopProcessLoading = true;

      const processId = this.info.data.id;

      this.stopProcess({
        controllerName: this.controllerName,
        id: processId,
      });
    },

    onShowLog(key, id) {
      const currentProcess = this.list.data.find((item) => item.id === id);

      if (!currentProcess.isInEmergency && currentProcess.completed) {
        this.isLoadingModalLog = true;
        this.getLog({
          controllerName: this.controllerName,
          id: id,
        })
          .then((res) => {
            this.modalLogFields = this.modalLogTable.modalField.map((item) => ({
              ...item,
              value: item.key === 'log' ? res : currentProcess[item.key],
            }));
          })
          .finally(() => {
            this.isLoadingModalLog = false;
          });
      } else if (currentProcess.isInEmergency) {
        this.modalLogFields = this.modalLogTable.modalField.map((item) => ({
          ...item,
          value:
            item.key === 'log'
              ? 'Обработка завершена аварийно. Лог недоступен.'
              : currentProcess[item.key],
        }));
      }
      this.showModalLog = true;
    },

    onCloseLogModal() {
      this.showModalLog = false;
    },
  },
};
</script>

<style lang="scss">
.long-process__breadcrumbs {
  margin-bottom: 30px;
}

.long-process__header {
  margin-bottom: 30px;
}

.long-process-success__label {
  font-weight: 500;
  margin-bottom: 15px;
}

.long-process-success__status {
  margin-bottom: 30px;
}

.long-process__logs-table {
  .datatable__cell:last-of-type {
    max-width: 320px;
  }
}

.long-process__button {
  width: auto;

  &--start {
    margin-bottom: 15px;
  }
}

.long-process__table-button {
  margin-right: 5px;

  font-size: 0;
  color: var(--gray);

  margin: 0;
  border: none;
  background: transparent;

  cursor: pointer;

  &:hover {
    color: var(--blue);
  }
}

.long-process-tabs {
  background-color: rgba(var(--rgb-blue), 0.04) !important;
}

.long-process-content__row {
  margin-bottom: 10px;
  display: flex;
}

.long-process-content__row-label {
  font-weight: 500;
  padding-right: 7px;
}

.long-process-content__files {
  display: flex;
  flex-direction: column;
  a {
    text-decoration: underline;
    color: var(--blue);
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}

.long-process-content__info {
  white-space: pre-line;
}

.long-process-content__info-text {
  white-space: pre-wrap;
}

.long-process-completed__row {
  margin-bottom: 15px;
  display: flex;
  &:last-of-type {
    margin: 0;
  }
}

.long-process-completed__label {
  font-weight: 500;
  padding-right: 5px;
  flex: 0 0 150px;
}

.long-process-completed__loader {
  position: absolute;
  top: 85px;
  bottom: 10px;
}

.long-process-completed__text {
  max-width: calc(100% - 150px);
  word-break: break-word;
  white-space: pre-line;
}

.long-process-completed .modal {
  min-height: 300px;
}

.long-process-completed .modal__body-wrapper {
  overflow: auto;
  flex: 1;
}

.form-modal .editable-form__column {
  max-width: 100%;
}

.form-modal .editable-form {
  flex-direction: column;
}

.form-modal__buttons {
  display: flex;
  margin: 0 -7.5px;
}

.form-modal__button {
  width: 50%;
  margin: 0 7.5px;
}
</style>